<template>
    <div v-frag >
        <!-- Desktop -->
        <tr v-if="$window.isDesktop" class="manager-order-position-update" >
            <td @click="save" >
                <v-ons-button v-show="hasModel" >
                    <v-ons-icon icon="md-check"></v-ons-icon>
                </v-ons-button>
            </td>
            <td class="col-name" >
                <input-proposal
                    ref="inputProposal"
                    :scrollPageOnFocus="true"
                    :disableScrollPage="true"
                    :disabled="editable.length > 0"
                    :urlSearch="urlSearch"
                    :addedPositions="addedPositions"
                    :disableCustomProposal="true"
                    :showRest="true"
                    @choose="chooseProposal"
                    />
            </td>
            <td class="col-count" >
                <div class="count-wrap">
                    <div v-show="hasModel" class="wrap">
                        <div class="col col-button" >
                            <div @click="decCount" class="btn" >-</div>
                        </div>

                        <div class="col col-input">
                            <input
                                ref="inputCount"
                                type="number"
                                class="input-count without-arrow"
                                v-model="model.count"
                            />
                        </div>
                        <div class="col col-button" >
                            <div @click="incCount" class="btn" >+</div>
                        </div>
                    </div>
                </div>

            </td>
            <td class="col-price" >
                <input
                    type="number"
                    class="input-price without-arrow"
                    v-show="hasModel"
                    v-model="model.price"
                />
            </td>
            <td class="col-action" @click="$emit('startEdit', model.pos_id)" >
                <v-ons-button v-show=hasModel @click="cancel" >
                    <v-ons-icon icon="md-block"></v-ons-icon>
                </v-ons-button>
            </td>
        </tr>

        <!-- Mobile -->
        <tr v-if="!$window.isDesktop" class="manager-order-position-update" >
            <td class="col-name" colspan="5" >
                <input-proposal
                    ref="inputProposal"
                    :scrollPageOnFocus="true"
                    :disableScrollPage="true"
                    :disabled="editable.length > 0"
                    :urlSearch="urlSearch"
                    :addedPositions="addedPositions"
                    :disableCustomProposal="true"
                    :showRest="true"
                    @choose="chooseProposal"
                    />
            </td>
        </tr>
        <tr v-if="!$window.isDesktop && hasModel" class="manager-order-position-update mobile" >
            <td colspan="5" >
                <div class="wrap " >
                    <div class="col xl-2-6 text-center" >
                        <v-ons-button @click="save" >
                            <v-ons-icon icon="md-check"></v-ons-icon>
                        </v-ons-button>

                        <v-ons-button @click="cancel" style="margin-left: 10px;" >
                            <v-ons-icon icon="md-block"></v-ons-icon>
                        </v-ons-button>
                    </div>
                    <div class="col xl-2-6" >
                        <div class="count-wrap">
                            <div class="wrap">
                                <div class="col col-button" >
                                    <div @click="decCount" class="btn" >-</div>
                                </div>

                                <div class="col col-input">
                                    <input
                                        ref="inputCount"
                                        type="number"
                                        class="input-count without-arrow"
                                        v-model="model.count"
                                    >
                                </div>
                                <div class="col col-button" >
                                    <div @click="incCount" class="btn" >+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col xl-2-6" >
                        <input type="number" class="input-price without-arrow" v-model="model.price" />
                    </div>
                </div>
            </td>
        </tr>
    </div>
</template>

<script>

import InputProposal from '~/component/InputProposalCreate'
import numeral from 'numeral'

export default {
    props: [
        'index',
        'urlSearch',
        'editable',
        'addedPositions',
    ],
    data() {
        return {
            model: {},
        }
    },
    created() {
        window.addEventListener('keydown', this.keyupListener);
    },
    destroyed() {
        window.removeEventListener('keydown', this.keyupListener);
    },
    methods: {
        incCount() {
            let mult = 1;
            let count = this.model.count;

            if (this.model.mult) {
                mult = parseFloat(this.model.mult);
            }

            if (!count) {
                count = mult;
            } else {
                count = parseFloat(count) + mult;
            }

            this.model.count = numeral(count).format('0[.][000]');

            if (!this.$window.isDesktop) {
                this.$refs.inputCount.focus();
            }
        },
        decCount() {
            let mult = 1;
            let count = this.model.count;

            if (this.model.mult) {
                mult = parseFloat(this.model.mult);
            }

            count = parseFloat(count) - mult;

            if (count <= 0) {
                count = '';
            } else {
                count = numeral(count).format('0[.][000]');
            }

            this.model.count = count;

            this.$emit('updateCount', this.model.pos_id);

            if (!this.$window.isDesktop) {
                this.$refs.inputCount.focus();
            }
        },
        chooseProposal(proposal) {
            let addedPosition = this.addedPositions.find(p => proposal.id == p.id);

            if (addedPosition) {
                this.$refs.inputProposal.clear();
                this.$emit('chooseAdded', addedPosition);
                return;
            }

            this.model = {
                product: proposal.id,
                price: proposal.price,
                name: proposal.name,
                mult: proposal.mult,
                unit: proposal.unit,
                count: '',
            };

            this.$nextTick(() => {
                this.$refs.inputCount.focus();
            });
        },
        save() {
            if (!this.hasModel) {
                return;
            }

            let position = {
                prd_id: this.model.product,
                count: this.model.count,
                price: this.model.price,
                unit: this.model.unit,
            }

            this.$emit('save', position);

            this.model = {};
            this.$refs.inputProposal.clear();
        },
        cancel() {
            this.model = {};
            this.$refs.inputProposal.clear();
        },
        keyupListener(event) {
            if (event.target.className === 'input-proposal') {
                return;
            }

            if (!this.hasModel) {
                return;
            }

            // Esc
            if (event.keyCode === 27) {
                this.cancel();
                event.preventDefault();
                return false;
            }

            // Enter
            if (event.keyCode === 13) {
                this.save();
                event.preventDefault();
                return false;
            }

            // plus
            if (event.keyCode === 107 || event.keyCode === 187) {
                event.preventDefault();
                this.incCount();
                return;
            }

            // minus
            if (event.keyCode === 109 || event.keyCode === 189) {
                event.preventDefault();
                this.decCount();

            }
        },
    },
    computed: {
        hasModel() {
            return Object.keys(this.model).length !== 0;
        }
    },
    components: {
        InputProposal,
    },
};
</script>