<template>
<v-ons-page class="manager-order-index">
    <v-ons-toolbar inline="true">
        <div class="left">
            <v-ons-back-button></v-ons-back-button>
        </div>

        <div class="center">Выберите заказы для объединения</div>
    </v-ons-toolbar>

    <div class="background">
        <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
            <v-ons-progress-circular indeterminate></v-ons-progress-circular>
        </div>
    </div>

    <div class="content">
        <div v-show="!error" >
            <div v-show="orders" >
                <ons-list>
                    <ons-list-item
                        v-for="(order, key) in orders"
                        v-if="showOrder(order)"
                        :key="order.id"
                        modifier="longdivider"
                        tappable
                        class="order-list-item"
                    >
                        <div class="left">
                            <v-ons-checkbox
                                v-model="checked"
                                :value="order.id"
                                :input-id="'order-' + key"
                                style="vertical-align: middle; margin: 0 12px 0 0;"
                            ></v-ons-checkbox>
                        </div>
                        <div class="center" >
                            <span :class="['list-item__title', order.status_id === 'sended' ? 'order-new' : '']" >
                                Заказ №{{order.id}} <span v-show="order.sended_at" >от {{ order.sended_at | moment("L") }}</span>
                                на сумму
                                <span v-if="order.isNearlyCost" style="margin-left: 5px" >~</span>
                                {{ order.hasDiscount ? order.totalCostWithDiscount : order.totalCost }} руб.
                            </span>
                            <span class="list-item__subtitle" >
                                Статус: {{ order.status }}
                            </span>

                            <v-list-item-subtitles :item="order" :fields="$user.getOrderSubtitles()" :selected="$user.orderSelectedSubtitles" />
                        </div>
                    </ons-list-item>

                    <div style="text-align: center; margin: 10px 0;" v-show="!showMore" >
                        <v-ons-button @click="showMore = true" style="min-width: 145px;" >
                            Показать ещё
                        </v-ons-button>
                    </div>
                </ons-list>
            </div>

            <div v-show="orders.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px" >Ничего не найдено</div>
        </div>

        <div v-show="error" style="text-align: center; font-size: 15px; margin-top: 50px" >
            <div style="margin-bottom: 15px; font-size: 20px;" >Произошла ошибка.</div>

            <v-ons-button @click="$emit('orders-update')">
                Повторить
            </v-ons-button>
        </div>

        <v-ons-fab position="bottom right" v-if="checked.length > 0" @click="merge">
            <v-ons-icon icon="md-format-valign-center"></v-ons-icon>
        </v-ons-fab>
    </div>

    <v-list-dialog ref="viewParamsModal" multiple="true" title="Опции просмотра" />
</v-ons-page>
</template>

<script>

import ListDialog from '~/component/ListDialog'
import ListItemSubtitles from '~/component/ListItemSubtitles'

export default {
    data() {
        return {
            id: null,
            order: null,
            orders: [],
            loading: false,
            error: false,
            showMore: false,
            checked: [],
        }
    },
    created() {
        this.id = this.$route.params.order_id;

        this.load()
    },
    methods: {
        load() {
            this.loading = true;

            let requests = [
                this.$http.get('/api/manager/orders/' + this.id + '/merge-list'),
                this.$http.get('/api/manager/orders/' + this.id),
            ]

            Promise.all(requests).then(responses => {
                this.orders = responses[0].data;
                this.order = responses[1].data.order;
                const shippingDate = this.order.shipping_date

                this.showMore = !this.orders.some((order) => order.shipping_date === shippingDate)

                this.loading = false;
            }, () => {
                this.loading = false;

                this.$ons.notification.toast({
                    message: 'Произошла ошибка.',
                    buttonLabel: 'OK'
                });
            });
        },
        showOrder(order) {
            return this.showMore || this.order.shipping_date === order.shipping_date
        },
        merge() {
            let msg = ''
            if (this.checked.length === 1) {
                msg = 'Заказ ' + this.checked[0] + ' будет удален'
            } else {
                msg = 'Заказы ' + this.checked.join(', ') + ' будут удалены'
            }

            msg += ', товары будут перенесены в заказ ' + this.order.id
            msg += "<br><br><b>Все пользовательские товары будут удалены!</b>"

            this.$ons.notification.confirm(msg, {
                title: 'Подтверждение',
                buttonLabels: ['Ок', 'Отмена']
            }).then(response => {
                if (response === 1) {
                    return
                }

                this.$http.post(
                    '/api/manager/orders/' + this.id + '/merge',
                    {orders: this.checked},
                ).then(() => {
                    this.loading = false;

                    this.$ons.notification.toast({
                        timeout: 3000,
                        message: 'Сохранено',
                        buttonLabel: 'OK',
                    });

                    this.$bus.$emit('order-update', this.id);
                    this.$bus.$emit('orders-update');
                    this.$router.goRouteBack();
                }, () => {
                    this.loading = false;
                    this.$ons.notification.toast({
                        buttonLabel: 'OK',
                        message: 'Произошла ошибка.',
                    });
                });
            });
        },
    },
    components: {
        'v-list-dialog': ListDialog,
        'v-list-item-subtitles': ListItemSubtitles,
    },
};
</script>