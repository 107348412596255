<template>
    <div class="input-proposal-wrap with-md-plus-icon" v-click-outside="blurHandler" >
        <form action="" autocomplete="off" @submit.prevent="() => {}">
            <v-ons-icon icon="md-plus"></v-ons-icon>

            <input
                ref="input"
                type="text"
                autocomplete="off"
                spellcheck="false"
                v-model="name"
                class="input-proposal"
                name="input-proposal"
                @keydown="keydownHandler"
                @keyup="keyupHandler"
                @focus="focusHandler"
                placeholder="Добавить товар"
                :disabled="inputDisabled || disabled"
            />
        </form>

        <div v-show="isVisibleProposals && !isLoading" @click="clear" class="clear-input-button" >
            <v-ons-icon icon="md-close" ></v-ons-icon>
        </div>

        <div v-show="isLoading" class="input-progress-circular" >
            <v-ons-progress-circular indeterminate></v-ons-progress-circular>
        </div>

        <div v-if="proposals.length > 0 && isVisibleProposals" class="proposals-outer" >
            <div class="proposals-wrap">
                <div v-for="(proposal, proposalIndex) in proposals"
                    class="proposal-wrap"
                    :id="'proposal-' + proposalIndex"
                    @click="chooseProposal(proposal)"
                    :class="{selected: proposalIndex == selectedProposal, added: isAdded(proposal)}"
                    :style="proposal.isCustom ? 'color: grey' : ''"
                    >

                    <div class="col col-name" v-show="isShowColumn('name')" >
                        <div>
                            {{ proposal.name }}

                            <div v-if="isAdded(proposal)" style="margin-top: 5px" >
                                <b>Товар уже добавлен, строка {{ addedPositions.find(p => proposal.id == p.id).index }}</b>
                            </div>

                            <span v-if="proposal.isCustom" > (пользовательский товар)</span>

                            <div v-if="showRest" style="margin-top: 5px">
                                {{ proposal.rest }} {{ proposal.unit }}
                            </div>

                            <v-ons-icon v-show="proposal.isInMyProducts" icon="md-star" title='Товар добавлен в "Мои товары"' class="star-icon" ></v-ons-icon>
                        </div>
                    </div>
                    <div class="col col-count" v-show="!proposal.isCustom && isShowColumn('count')"  >
                        <div>
                            {{proposal.unit}}
                        </div>
                    </div>
                    <div class="col col-price" v-show="!proposal.isCustom && isShowColumn('price')"  >
                        <div v-if="proposal.is_wait" >ждём</div>
                        <div v-else :class="['price-wrap', proposal.specialPrice.isShowMark ? 'is-low-special-price' : '']" >
                            {{ proposal.price }} р/{{ proposal.unit }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from "jquery"
import {from, merge, of} from 'rxjs'
import {catchError, concatMap, debounceTime, distinctUntilChanged, filter, map, pluck, tap} from 'rxjs/operators'


export default {
    props: [
        'model',
        'disableScrollPage',
        'inputDisabled',
        'isFocused',
        'columns',
        'addedPositions',
        'disabled',
        'preventAddedChoose',
        'disableCustomProposal',
        'urlSearch',
        'urlSearchParams',
        'showRest',
    ],
    data() {
        let name = this.model ? this.model : '';

        return {
            countQueries: 0,
            isVisibleProposals: false,
            name: name,
            proposals: [],
            searching: false,
            selectedProposal: null,
            scrolling: false,
            turnOffWatchName: false,
        }
    },
    subscriptions () {
        const q$ = this.$watchAsObservable('name').pipe(
            pluck('newValue'),
            map(q => q.trim()),
            tap((q) => {
                if (!q) {
                    this.proposals = [];
                    this.hideProposals();
                } else {
                  this.searching = true
                }
            }),
            debounceTime(500),
            distinctUntilChanged(),
            filter(q => q.length > 0),
            filter(() => {
                if (this.turnOffWatchName) {
                    this.turnOffWatchName = false;
                    return false;
                }

                return true;
            }),
        );

        const inputFocus$ = this.$eventToObservable('inputFocus').pipe(
            map(() => {
                return this.name.trim();
            }),
            filter(q => q.length > 0),
            filter(() => !this.isVisibleProposals),
        );

        return {
            results: merge(inputFocus$, q$).pipe(
                tap(() => this.countQueries++),
                concatMap(() => {
                    if (!this.searching) {
                        return of([]);
                    }

                    let url = this.urlSearch || '/api/product/search';
                    let params = this.urlSearchParams || {};

                    params.text = this.name;

                    return from(this.$http.get(url, {params: params}));
                }),
                map(response => {
                    return response.data;
                }),
                catchError(() => {
                    this.$ons.notification.toast({
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                    return of([]);
                }),
                map(response => {
                    this.proposals = response || [];

                    if (!this.disableCustomProposal) {
                        this.proposals.push({
                            name: this.name,
                            isCustom: true,
                            specialPrice: {
                                isLow: false,
                            },
                        });
                    }

                    this.countQueries--;

                    if (this.searching) {
                        this.showProposals();
                    }
                }),
            )
        }
    },
    methods: {
        keyupHandler() {
            this.$emit('keyup');

            if (!this.name) {
                this.hideProposals();
            }
        },
        focusHandler(e) {
            if (this.scrolling) {
                return;
            }

            this.$emit('focus', e);
            this.$emit('inputFocus');
            this.scrollPage(this.$refs.input);
            this.turnOffWatchName = false;
        },
        blurHandler() {
            this.$emit('blur');
            this.hideProposals();
        },
        keydownHandler(e) {
            this.scrollPage(this.$refs.input);
            this.$emit('keydown');

            // 40 down
            if (e.keyCode === 40) {
                e.preventDefault();

                if (this.selectedProposal == null) {
                    this.selectedProposal = 0;
                } else {
                    if (this.selectedProposal + 2 > this.proposals.length) {
                        this.selectedProposal = 0;
                    } else {
                        this.selectedProposal += 1;
                    }
                }

                this.scrollToElement();

                return false;
            }

            // 38 up
            if (e.keyCode === 38) {
                e.preventDefault();

                if (this.selectedProposal == null) {
                    this.selectedProposal = 0;
                } else {
                    if (this.selectedProposal-1 < 0) {
                        this.selectedProposal = this.proposals.length - 1;
                    } else {
                        this.selectedProposal -= 1;
                    }
                }

                this.scrollToElement();

                return false;
            }

            if (e.keyCode === 13) {
                if (!this.name) {
                    return;
                }

                if (this.selectedProposal != null) {
                    this.chooseProposal(this.proposals[this.selectedProposal]);
                    this.selectedProposal = null;
                }
            }
        },
        scrollToElement() {
            let $selectedProposal = $('#proposal-' + this.selectedProposal);
            let $proposalsOuter = $('.proposals-outer');
            let outerScrollTop = $proposalsOuter.scrollTop();
            let scrollTop = 0;
            let outerHeight = $proposalsOuter.height();

            if (!$selectedProposal) {
                return;
            }

            let top = $selectedProposal.position().top;
            let height = $selectedProposal.height();

            // Если нижняя граница выходит за div то поднимаем наверх
            if (top + height > outerHeight) {
                scrollTop = outerScrollTop + (top - outerHeight + height);
            } else if(top < 10) {
                scrollTop = outerScrollTop + top - 10;
            } else {
                return;
            }

            $proposalsOuter.animate({
                scrollTop: scrollTop,
            }, 50);
        },
        chooseProposal(proposal) {
            let addedPosition = this.addedPositions.find(p => proposal.id && proposal.id == p.id);

            if (this.preventAddedChoose && addedPosition) {
                return;
            }

            this.turnOffWatchName = true;
            this.name = proposal.name;
            this.hideProposals();
            this.$emit('choose', proposal);
            this.searching = false
        },
        showProposals() {
            let $pageContent = $('.page__content');

            this.$emit('showProposals');

            this.isVisibleProposals = true;

            if (this.disableScrollPage) {
                $pageContent.addClass('no-scroll');
                $pageContent.css('padding-right', this.$window.getScrollbarWidth() + 'px');
            }

            this.$nextTick(() => {
                this.scrollPage(this.$refs.input, 1);
                this.resizeProposals()
            });
            $(window).on('resize', this.resizeProposals);
        },
        hideProposals() {
            let $pageContent = $('.page__content');
            this.$emit('hideProposals');

            this.isVisibleProposals = false;
            this.selectedProposal = null;

            if (this.disableScrollPage) {
                $pageContent.removeClass('no-scroll');
                $pageContent.css('padding-right', 0);
            }

            $(window).off('resize', this.resizeProposals);
            this.$forceUpdate();
        },
        resizeProposals() {
            let $el = $(this.$el);
            if ($el.find('.proposals-outer').length === 0) {
                return;
            }
            let offset = $el.find('.proposals-outer').offset();
            let height = $(window).height() - (offset.top - $(document).scrollTop());
            $el.find('.proposals-outer').css({maxHeight: height + 'px'})
        },
        scrollPage(target, duration) {
            duration = duration || 300;
            let $input = $(target);
            let $target = $(target);
            let offset = $target.position().top;
            $target = $target.offsetParent();

            while (!$target.hasClass('page__content')) {
                offset += $target.position().top;
                $target = $target.offsetParent();
            }

            offset += $target.scrollTop();
            offset -= 15;

            let scrollTop = $target.scrollTop();

            if (offset < 1) {
                return;
            }

            if (scrollTop - 5 < offset && scrollTop + 5 > offset) {
                return;
            }

            $input.blur();
            this.scrolling = true;

            $target.animate({
                scrollTop: offset
            }, duration, () => {
                $input.focus();

                this.$nextTick(() => {
                    this.scrolling = false;
                });
            });
        },
        isShowColumn(column) {
            if (!this.columns || !this.columns.length) {
                return true;
            }

            return this.columns.indexOf(column) !== -1;
        },
        isAdded(position) {
            return typeof this.addedPositions != 'undefined' && this.addedPositions.find(p => position.id && position.id == p.id);
        },
        clear() {
            this.name = '';
            this.hideProposals();
            this.proposals = [];

            if (this.previousRequest) {
                this.previousRequest.abort();
            }
        },
        setFocus() {
            this.$refs.input.focus();
        },
        unsetFocus() {
            this.$refs.input.blur();
        },
    },
    computed: {
        focus() {
            return this.$window.hasFocus(this.$refs.input);
        },
        isLoading() {
            return this.countQueries > 0;
        },
    },
    watch: {
        model(val) {
            this.name = val ? val : '';
        },
        name(val) {
            this.$emit('update:model', val);
        },
        focus(val) {
            this.$emit('update:isFocused', val);
        },
    }
}
</script>