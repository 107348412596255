var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-dialog",
    {
      attrs: { visible: _vm.visible, modifier: "fullscreen" },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        }
      }
    },
    [
      _c(
        "v-ons-page",
        [
          _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "v-ons-toolbar-button",
                  { on: { click: _vm.close } },
                  [_c("v-ons-icon", { attrs: { icon: "md-close" } })],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "center" }, [_vm._v("Контактное лицо")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "v-ons-toolbar-button",
                  { on: { click: _vm.save } },
                  [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [_vm._v("Должность")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { name: "person_post" },
                    model: {
                      value: _vm.person.post,
                      callback: function($$v) {
                        _vm.$set(_vm.person, "post", $$v)
                      },
                      expression: "person.post"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Имя")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { name: "person_name" },
                    model: {
                      value: _vm.person.name,
                      callback: function($$v) {
                        _vm.$set(_vm.person, "name", $$v)
                      },
                      expression: "person.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Телефон")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { type: "tel", name: "person_phone" },
                    model: {
                      value: _vm.person.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.person, "phone", $$v)
                      },
                      expression: "person.phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-list-header", [_vm._v("Email")]),
              _vm._v(" "),
              _c(
                "v-ons-list-item",
                [
                  _c("v-ons-input", {
                    attrs: { type: "email", name: "person_email" },
                    model: {
                      value: _vm.person.email,
                      callback: function($$v) {
                        _vm.$set(_vm.person, "email", $$v)
                      },
                      expression: "person.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "need_to_notify_buyer" }
                  },
                  [
                    _vm._v(
                      "\n                    Дублировать уведомления о заказах\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    (почта закупщика)\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        "input-id": "need_to_notify_buyer",
                        disabled: !_vm.person.email
                      },
                      model: {
                        value: _vm.person.need_to_notify_buyer,
                        callback: function($$v) {
                          _vm.$set(_vm.person, "need_to_notify_buyer", $$v)
                        },
                        expression: "person.need_to_notify_buyer"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("v-ons-list-item", [
                _c(
                  "label",
                  {
                    staticClass: "center label-reset",
                    attrs: { for: "need_to_notify_accountant" }
                  },
                  [
                    _vm._v(
                      "\n                    Дублировать счета\n                    "
                    ),
                    _c("br"),
                    _vm._v(
                      "\n                    (почта бухгалтера)\n                "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("v-ons-checkbox", {
                      staticStyle: {
                        "vertical-align": "middle",
                        margin: "0 12px 0 0"
                      },
                      attrs: {
                        "input-id": "need_to_notify_accountant",
                        disabled: !_vm.person.email
                      },
                      model: {
                        value: _vm.person.need_to_notify_accountant,
                        callback: function($$v) {
                          _vm.$set(_vm.person, "need_to_notify_accountant", $$v)
                        },
                        expression: "person.need_to_notify_accountant"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-ons-list",
            [
              _c("v-ons-list-header", [
                _vm._v("Указывать телефон для логистики")
              ]),
              _vm._v(" "),
              _vm._l(_vm.branches, function(branch, key) {
                return _c("v-ons-list-item", { key: branch.id }, [
                  _c(
                    "label",
                    {
                      staticClass: "center label-reset",
                      attrs: { for: "branch-" + key }
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            [branch.stringAddress, branch.actual_name]
                              .filter(function(i) {
                                return i
                              })
                              .join(", ")
                          ) +
                          "\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("v-ons-checkbox", {
                        staticStyle: {
                          "vertical-align": "middle",
                          margin: "0 12px 0 0"
                        },
                        attrs: {
                          value: branch.id,
                          "input-id": "branch-" + key
                        },
                        model: {
                          value: _vm.person.branches,
                          callback: function($$v) {
                            _vm.$set(_vm.person, "branches", $$v)
                          },
                          expression: "person.branches"
                        }
                      })
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-47f56a82", { render: render, staticRenderFns: staticRenderFns })
  }
}