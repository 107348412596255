<template>
    <v-ons-dialog :visible.sync="visible" modifier="fullscreen" >
        <v-ons-page>
            <v-ons-toolbar inline="true" >
                <div class="left">
                    <v-ons-toolbar-button @click="close" >
                        <v-ons-icon icon="md-close"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
                <div class="center">Контактное лицо</div>
                <div class="right">
                    <v-ons-toolbar-button @click="save" >
                        <v-ons-icon icon="md-check"></v-ons-icon>
                    </v-ons-toolbar-button>
                </div>
            </v-ons-toolbar>

            <v-ons-list>
                <v-ons-list-header>Должность</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="person.post" name="person_post" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-header>Имя</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="person.name" name="person_name" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-header>Телефон</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="person.phone" type="tel" name="person_phone" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-header>Email</v-ons-list-header>
                <v-ons-list-item>
                    <v-ons-input v-model="person.email" type="email" name="person_email" ></v-ons-input>
                </v-ons-list-item>

                <v-ons-list-item>
                    <label for="need_to_notify_buyer" class="center label-reset" >
                        Дублировать уведомления о заказах
                        <br>
                        (почта закупщика)
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="person.need_to_notify_buyer"
                            input-id="need_to_notify_buyer"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                            :disabled="!person.email"
                        ></v-ons-checkbox>
                    </div>
                </v-ons-list-item>

                <v-ons-list-item>
                    <label for="need_to_notify_accountant" class="center label-reset" >
                        Дублировать счета
                        <br>
                        (почта бухгалтера)
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="person.need_to_notify_accountant"
                            input-id="need_to_notify_accountant"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                            :disabled="!person.email"
                        ></v-ons-checkbox>
                    </div>
                </v-ons-list-item>
            </v-ons-list>

            <v-ons-list>
                <v-ons-list-header>Указывать телефон для логистики</v-ons-list-header>
                <v-ons-list-item v-for="(branch, key) in branches" :key="branch.id" >
                    <label :for="'branch-' + key" class="center label-reset" >
                        {{ [branch.stringAddress, branch.actual_name].filter(i => i).join(', ')  }}
                    </label>
                    <div class="right">
                        <v-ons-checkbox
                            v-model="person.branches"
                            :value="branch.id"
                            :input-id="'branch-' + key"
                            style="vertical-align: middle; margin: 0 12px 0 0;"
                        ></v-ons-checkbox>
                    </div>
                </v-ons-list-item>
            </v-ons-list>
        </v-ons-page>
    </v-ons-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            resolve: null,
            reject: null,

            branches: [],
            person: {
                post: '',
                name: '',
                email: '',
                phone: '',
                need_to_notify_buyer: false,
                need_to_notify_accountant: false,
                branches: [],
            }
        }
    },
    created() {

    },
    methods: {
        show(branches, person) {
            this.branches = branches;
            this.visible = true;
            this.reset();

            if (person) {
                this.person = {
                    post: person.post,
                    name: person.name,
                    email: person.email,
                    phone: person.phone,
                    need_to_notify_buyer: person.need_to_notify_buyer,
                    need_to_notify_accountant: person.need_to_notify_accountant,
                    branches: person.branches,
                };
            }

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        close() {
            this.visible = false;
        },
        save() {
            this.visible = false;
            this.resolve(this.person);
        },
        reset() {
            this.person = {
                post: '',
                name: '',
                email: '',
                phone: '',
                need_to_notify_buyer: false,
                need_to_notify_accountant: false,
                branches: [],
            }
        }
    },
};
</script>