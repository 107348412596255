<template>
    <v-ons-page>
        <v-ons-toolbar>
            <div class="left">
                <v-ons-toggle-sidebar-button></v-ons-toggle-sidebar-button>
            </div>

            <div class="center">Ошибки связей</div>

            <div class="right" >
                <toolbar-popover-menu popover-class="stick-right" >
                    <ons-list-item @click="onRemoveAll" >Удалить всё</ons-list-item>
                    <ons-list-item @click="$router.push('/manager/product-avail')" >В ожидании</ons-list-item>
                    <ons-list-header>Отображать по:</ons-list-header>
                    <ons-list-item @click="$router.push({name: 'manager-product-link-users'})" >
                        <div class="left" style="min-width: 25px; justify-content: center;" >

                        </div>
                        <div class="center" style="padding-left: 0" >
                            пользователям
                        </div>
                    </ons-list-item>
                    <ons-list-item @click="$router.push({name: 'manager-product-link-products'})" >
                        <div class="left" style="min-width: 25px; justify-content: center;" >
                            <v-ons-icon icon="md-check"></v-ons-icon>
                        </div>
                        <div class="center" style="padding-left: 0"  >
                            товарам
                        </div>
                    </ons-list-item>
                </toolbar-popover-menu>
            </div>
        </v-ons-toolbar>

        <div class="background">
            <div v-show="loading" class="page-loading" :style="{height: $window.height - 56 + 'px'}" >
                <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
        </div>

        <div class="content">
            <ons-list-item v-show="storages.length > 1" modifier="chevron longdivider" @click="showChooseStorage">
                <span class="list-item__title">Склад: {{ selectedStorageName }}</span>
                <span v-if="selectedStorage && !selectedStorage.is_configured" style="color: red" class="list-item__subtitle">
                    Не настроено
                </span>
            </ons-list-item>

            <v-ons-card>
                Эти товары добавлены у клиентов в избранное, но они закончились.
                Замените товары на аналоги или присвойте товару статус "ждём"
            </v-ons-card>

            <ons-list v-show="products" >
                <ons-list-item
                    v-for="product in products"
                    :key="product.id"
                    modifier="chevron longdivider"
                    tappable
                    @click="clickProduct(product)" >

                    <div class="center" >
                        <span class="list-item__title" >{{ product.name }}</span>
                        <span class="list-item__subtitle" v-show="'product' in product && !product.product" >
                            Пользовательский товар
                        </span>
                        <span class="list-item__subtitle">
                            {{ product.deletedAt }}
                        </span>
                    </div>
                </ons-list-item>
            </ons-list>

            <div v-show="products.length === 0" style="text-align: center; font-size: 15px; margin-top: 50px" >
                Ошибок связей не найдено
            </div>
        </div>

        <v-list-dialog ref="chooseStorageDialog" title="Выберите склад" :items="storages" />
    </v-ons-page>
</template>

<script>
import ListDialog from "~/component/ListDialog";

export default {
    data() {
        return {
            loading: true,

            choseStorage: null,
            storages: [],
            products: [],
        }
    },
    created() {
        this.loadStorages().then(() => {
            this.load();
        });

        if (this.$route.params.storage_id) {
            this.choseStorage = this.$route.params.storage_id;
        }

        this.$bus.$on('loadProductsLink', this.load);
    },
    destroyed() {
        this.$bus.$off('loadProductsLink', this.load);
    },
    methods: {
        clickProduct(product) {
            let type = ('product' in product && !product.product) ? 'userproduct' : 'product';

            this.$router.push({
                name: 'manager-product-link-products-users',
                params: {
                    type: type,
                    product_id: product.id,
                    storage_id: this.choseStorage,
                },
            });
        },
        load() {
            return this.$http.get(
                '/api/manager/product-link/get-products',
                {params: {storageId: this.choseStorage}}
            ).then(response => {
                this.products = response.data;

                this.$nextTick(() => {
                    this.loading = false;
                });
            }, () => {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.$ons.notification.toast({
                    buttonLabel: 'OK',
                    message: 'Произошла ошибка.',
                });
            });
        },
        loadStorages() {
            return this.$http.get(
                '/api/manager/storages',
                {params: {withConfigured: true}}
            ).then(response => {
                this.storages = response.data;

                if (!this.choseStorage) {
                    let defaultStorage = this.storages.find(s => s.is_default) || this.storages[0];
                    this.choseStorage = defaultStorage.id;
                }
            });
        },
        showChooseStorage() {
            this.$refs.chooseStorageDialog.show().then(response => {
                this.choseStorage = response;
                this.load();
            });
        },
        onRemoveAll() {
            this.$ons.notification.confirm('У клиентов буду удалены товары, по которым обнаружены ошибки связи', {
                title: 'Подтверждение',
                buttonLabels: ['Отмена', 'OK']
            }).then(response => {
                if (response === 0) {
                    return;
                }
                this.loading = true

                this.$http.post('/api/manager/product-link/remove-all', {storageId: this.choseStorage}).then(() => {
                    this.load()
                }, () => {
                    this.loading = false

                    this.$ons.notification.toast({
                        timeout: 1000,
                        message: 'Произошла ошибка.',
                        buttonLabel: 'OK'
                    });
                });
            });
        }
    },
    computed: {
        selectedStorage() {
            return this.storages.find(s => s.id === parseInt(this.choseStorage));
        },
        selectedStorageName() {
            let storage = this.storages.find(s => s.id === parseInt(this.choseStorage));

            if (!storage) {
                return 'Неизвестно';
            }

            return storage.name;
        },
    },
    components: {
        'v-list-dialog': ListDialog,
    },
};
</script>