var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("v-ons-back-button", {
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.goBack.apply(null, arguments)
                }
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm.order.id
            ? _c("div", [
                _c("span", [_vm._v("Заказ №" + _vm._s(_vm.order.id))]),
                _vm._v(" "),
                _vm.order.status_id === "draft" && _vm.order.was_canceled
                  ? _c("span", [_vm._v(" (отменён)")])
                  : _vm._e()
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.loading,
                expression: "!loading"
              }
            ],
            staticClass: "right"
          },
          [
            _c(
              "v-ons-toolbar-button",
              { on: { click: _vm.print } },
              [_c("v-ons-icon", { attrs: { icon: "md-print" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      !_vm.$user.isConfirmEmailSendingEnabled &&
                      _vm.order.status_id === "confirmed",
                    expression:
                      "!$user.isConfirmEmailSendingEnabled && order.status_id === 'confirmed'"
                  }
                ],
                attrs: { title: "Отправить подтверждение заказа" },
                on: { click: _vm.sendConfirmEmail }
              },
              [
                _c("v-ons-icon", {
                  style: {
                    color: _vm.order.confirm_sended_at ? "#ffbb3d" : "white"
                  },
                  attrs: { icon: "md-assignment-return" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.order.status_id === "manager_editing",
                    expression: "order.status_id === 'manager_editing'"
                  }
                ],
                on: { click: _vm.endEditConfirmedOrder }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-ons-toolbar-button",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: ["sended", "manager_draft"].includes(
                      _vm.order.status_id
                    ),
                    expression:
                      "['sended', 'manager_draft'].includes(order.status_id)"
                  }
                ],
                on: { click: _vm.preConfirmOrder }
              },
              [_c("v-ons-icon", { attrs: { icon: "md-check" } })],
              1
            ),
            _vm._v(" "),
            _c(
              "toolbar-popover-menu",
              { attrs: { "popover-class": "stick-right" } },
              [
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order && _vm.order.status_id === "sended",
                      expression: "order && order.status_id === 'sended'"
                    }
                  ],
                  attrs: { text: "Подтвердить", icon: "md-check" },
                  on: {
                    click: function($event) {
                      return _vm.preConfirmOrder()
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order,
                      expression: "order"
                    }
                  ],
                  attrs: { text: "Печать", icon: "md-print" },
                  on: {
                    click: function($event) {
                      return _vm.print()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.order
                  ? _c("toolbar-popover-menu-item", {
                      attrs: {
                        text: "Отправить счет",
                        icon: _vm.order.is_invoice_sended
                          ? "md-assignment-check"
                          : "md-assignment-o"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendInvoiceForPayment()
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.order && _vm.order.status_id === "confirmed"
                  ? _c("toolbar-popover-menu-item", {
                      attrs: { text: "Редактировать", icon: "md-edit" },
                      on: { click: _vm.startEditConfirmedOrder }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.order
                  ? _c("toolbar-popover-menu-item", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            !_vm.$user.isConfirmEmailSendingEnabled &&
                            _vm.order.status_id === "confirmed",
                          expression:
                            "!$user.isConfirmEmailSendingEnabled && order.status_id === 'confirmed'"
                        }
                      ],
                      attrs: {
                        text: "Отправить подтверждение",
                        icon: "md-assignment-return"
                      },
                      on: {
                        click: function($event) {
                          return _vm.sendConfirmEmail()
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.order &&
                        (_vm.order.status_id === "sended" ||
                          _vm.order.status_id === "confirmed"),
                      expression:
                        "order && (order.status_id === 'sended' || order.status_id === 'confirmed')"
                    }
                  ],
                  attrs: {
                    text: "Объединить",
                    icon: "md-format-valign-center"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        _vm.$router.currentRoute.path + "/merge"
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.order &&
                        (_vm.order.status_id === "sended" ||
                          _vm.order.status_id === "confirmed"),
                      expression:
                        "order && (order.status_id === 'sended' || order.status_id === 'confirmed')"
                    }
                  ],
                  attrs: { text: "Отменить", icon: "md-block" },
                  on: {
                    click: function($event) {
                      return _vm.cancelOrder()
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.order &&
                        (_vm.order.status_id === "sended" ||
                          _vm.order.status_id === "manager_editing"),
                      expression:
                        "order && (order.status_id === 'sended' || order.status_id === 'manager_editing')"
                    }
                  ],
                  attrs: { text: "Изменить тип цен", icon: "md-money" },
                  on: {
                    click: function($event) {
                      return _vm.showPriceTypesDialog()
                    }
                  }
                }),
                _vm._v(" "),
                _c("toolbar-popover-menu-item", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order,
                      expression: "order"
                    }
                  ],
                  attrs: { text: "Профиль клиента", icon: "md-account" },
                  on: {
                    click: function($event) {
                      return _vm.$router.push(
                        "/manager/users/" + _vm.order.user_id
                      )
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          {
            staticClass: "order-view-page",
            style: { padding: "15px 5px", "padding-bottom": _vm.paddingBottom }
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _vm._v("\n                Email: "),
              _c("a", { attrs: { href: "mailto:" + _vm.user.email } }, [
                _vm._v(_vm._s(_vm.user.email))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _vm._v("\n                Телефон:\n                "),
              _vm.user.phone
                ? _c("a", { attrs: { href: "tel:" + _vm.user.phone } }, [
                    _vm._v(_vm._s(_vm.user.phone))
                  ])
                : _c("span", [_vm._v("не указан")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "control-label" }, [
                _vm._v("\n                    Поставщик\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _vm.canEditOrder
                  ? _c(
                      "div",
                      {
                        staticClass: "select-material select--material select"
                      },
                      [
                        _vm.providerEntities.length === 0
                          ? _c("div", { staticStyle: { color: "#898989" } }, [
                              _vm._v(
                                "\n                            Нет ни одного юр. лица\n                        "
                              )
                            ])
                          : _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.order.provider_entity_id,
                                    expression: "order.provider_entity_id"
                                  }
                                ],
                                staticClass:
                                  "select-input select-input--material",
                                attrs: { title: _vm.titleChoosedEntity },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.order,
                                      "provider_entity_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                !_vm.order.provider_entity_id
                                  ? _c(
                                      "option",
                                      {
                                        attrs: { disabled: "" },
                                        domProps: { value: null }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Не выбрано\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.providerEntities, function(entity) {
                                  return _c(
                                    "option",
                                    {
                                      key: entity.id,
                                      domProps: { value: entity.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(entity.alias) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                      ]
                    )
                  : _c("div", [
                      _vm.order.providerEntity
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.order.providerEntity.alias))
                          ])
                        : _c("span", [_vm._v("нет данных")])
                    ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "control-label" }, [
                _vm._v("\n                    Покупатель\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _vm.canEditOrder
                  ? _c(
                      "div",
                      {
                        staticClass: "select-material select--material select"
                      },
                      [
                        _vm.entities.length === 0
                          ? _c("div", { staticStyle: { color: "#898989" } }, [
                              _vm._v(
                                "\n                            Нет ни одного юр. лица\n                        "
                              )
                            ])
                          : _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.order.entity_id,
                                    expression: "order.entity_id"
                                  }
                                ],
                                staticClass:
                                  "select-input select-input--material",
                                attrs: { title: _vm.titleChoosedEntity },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.order,
                                      "entity_id",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                !_vm.order.entity_id
                                  ? _c(
                                      "option",
                                      {
                                        attrs: { disabled: "" },
                                        domProps: { value: null }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Не выбрано\n                            "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.entities, function(entity) {
                                  return _c(
                                    "option",
                                    {
                                      key: entity.id,
                                      domProps: { value: entity.id }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(entity.alias) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                })
                              ],
                              2
                            )
                      ]
                    )
                  : _c("div", [
                      _vm.order.entity && _vm.order.entity.alias
                        ? _c("span", [_vm._v(_vm._s(_vm.order.entity.alias))])
                        : _c("span", [_vm._v("нет данных")])
                    ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.order.shippingMethod === "delivery",
                    expression: "order.shippingMethod === 'delivery'"
                  }
                ],
                staticClass: "form-group"
              },
              [
                _c("div", { staticClass: "control-label" }, [
                  _vm._v(
                    "\n                    Адрес доставки\n                "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-control" }, [
                  _vm.canEditOrder
                    ? _c(
                        "div",
                        {
                          staticClass: "select-material select--material select"
                        },
                        [
                          _vm.branches.length === 0
                            ? _c("div", { staticStyle: { color: "#898989" } }, [
                                _vm._v(
                                  "\n                            Нет ни одного адреса доставки\n                        "
                                )
                              ])
                            : _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.order.branch_id,
                                      expression: "order.branch_id"
                                    }
                                  ],
                                  staticClass:
                                    "select-input select-input--material",
                                  attrs: { title: _vm.titleChoosedBranch },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.order,
                                        "branch_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  !_vm.order.branch_id
                                    ? _c(
                                        "option",
                                        {
                                          attrs: { disabled: "" },
                                          domProps: { value: null }
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Не выбрано\n                            "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.branches, function(branch) {
                                    return _c(
                                      "option",
                                      {
                                        key: branch.id,
                                        domProps: { value: branch.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              branch.stringAddressWithName
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                        ]
                      )
                    : _c("span", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.order.branch
                                ? _vm.order.branch.stringAddressWithName
                                : "не указан"
                            ) +
                            "\n                    "
                        )
                      ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.order.branch && !_vm.order.branch.route,
                        expression: "order.branch && !order.branch.route"
                      }
                    ],
                    staticStyle: { border: "1px solid #ff8d00", padding: "2px" }
                  },
                  [
                    _c("div", { staticClass: "wrap" }, [
                      _c("div", { staticClass: "col xl-1-2" }, [
                        _c("b", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("Маршрут не привязан")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.routes.length > 0,
                              expression: "routes.length > 0"
                            }
                          ],
                          staticClass: "col xl-1-2 xl-right"
                        },
                        [
                          _c(
                            "v-ons-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.linkRoute(_vm.order.branch)
                                }
                              }
                            },
                            [_vm._v("Привязать")]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "control-label" }, [
                _vm._v("\n                    Способ оплаты\n                ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _vm.canEditOrder
                  ? _c(
                      "div",
                      {
                        staticClass: "select-material select--material select"
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.order.paymentMethod,
                                expression: "order.paymentMethod"
                              }
                            ],
                            staticClass: "select-input select-input--material",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.order,
                                  "paymentMethod",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.paymentMethods, function(method) {
                            return _c(
                              "option",
                              {
                                key: method.id,
                                domProps: { value: method.id }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(method.name) +
                                    "\n                            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  : _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.order.paymentMethodLabel) +
                          "\n                    "
                      )
                    ])
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.order.shippingMethod === "delivery" &&
                      [1, 8].includes(_vm.order.paymentMethod),
                    expression:
                      "order.shippingMethod === 'delivery' && [1, 8].includes(order.paymentMethod)"
                  }
                ],
                staticClass: "form-group"
              },
              [
                _vm._v("\n                Сумма:\n                "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.order.invoice_amount,
                      expression: "order.invoice_amount"
                    }
                  ],
                  attrs: { type: "number", placeholder: "по накладной" },
                  domProps: { value: _vm.order.invoice_amount },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.order, "invoice_amount", $event.target.value)
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("div", { staticClass: "control-label" }, [
                _vm._v(
                  "\n                    Способ отгрузки\n                "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _vm.canEditOrder
                  ? _c(
                      "div",
                      {
                        staticClass: "select-material select--material select"
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.order.shippingMethod,
                                expression: "order.shippingMethod"
                              }
                            ],
                            staticClass: "select-input select-input--material",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.order,
                                  "shippingMethod",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          _vm._l(_vm.shippingMethods, function(method) {
                            return _c(
                              "option",
                              {
                                key: method.id,
                                domProps: { value: method.id }
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(method.name) +
                                    "\n                            "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    )
                  : _c("span", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.order.shippingMethodLabel) +
                          "\n                    "
                      )
                    ])
              ])
            ]),
            _vm._v(" "),
            _vm.canEditOrder
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _vm._v(
                      "\n                Дата отгрузки:\n                "
                    ),
                    _c("v-input-datepicker", {
                      staticStyle: { display: "inline-block", width: "200px" },
                      model: {
                        value: _vm.order.shipping_date,
                        callback: function($$v) {
                          _vm.$set(_vm.order, "shipping_date", $$v)
                        },
                        expression: "order.shipping_date"
                      }
                    })
                  ],
                  1
                )
              : _c("div", { staticClass: "form-group" }, [
                  _vm._v("Дата отгрузки: " + _vm._s(_vm.order.shippingDate))
                ]),
            _vm._v(" "),
            _vm.order.manager_comment
              ? _c(
                  "p",
                  {
                    staticStyle: {
                      border: "1px solid #ff8d00",
                      padding: "2px",
                      cursor: "pointer"
                    },
                    attrs: { title: "Скопировать комментарий" },
                    on: {
                      click: function($event) {
                        return _vm.copyToClipboard(_vm.order.manager_comment)
                      }
                    }
                  },
                  [
                    _c("b", [
                      _vm._v(
                        "Комментарий менеджера: " +
                          _vm._s(_vm.order.manager_comment)
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.order.comment
              ? _c(
                  "p",
                  {
                    staticStyle: {
                      border: "1px solid #ff8d00",
                      padding: "2px",
                      cursor: "pointer"
                    },
                    attrs: { title: "Скопировать комментарий" },
                    on: {
                      click: function($event) {
                        return _vm.copyToClipboard(_vm.order.comment)
                      }
                    }
                  },
                  [
                    _c("b", [
                      _vm._v("Комментарий: " + _vm._s(_vm.order.comment))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _vm._v("\n                Номер накладной:\n                "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order.invoice_number,
                    expression: "order.invoice_number"
                  }
                ],
                attrs: {
                  disabled: !["sended", "manager_editing"].includes(
                    _vm.order.status_id
                  )
                },
                domProps: { value: _vm.order.invoice_number },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.order, "invoice_number", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "table", staticStyle: { position: "relative" } },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", [_vm._v("Наименование")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Кол-во")]),
                    _vm._v(" "),
                    _c("th", [
                      _vm._v("Цена "),
                      _vm.order.hasDiscount
                        ? _c("span", [_vm._v("(со скидкой)")])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("th", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isShowColAction,
                          expression: "isShowColAction"
                        }
                      ]
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("v-tbody", {
                  attrs: {
                    order: _vm.order,
                    isShowColAction: _vm.isShowColAction,
                    editable: _vm.editable,
                    units: _vm.units
                  },
                  on: {
                    startEdit: _vm.startEdit,
                    cancel: _vm.cancelEdit,
                    remove: _vm.removePosition,
                    save: _vm.savePosition,
                    insertPosition: _vm.insertPosition,
                    chooseAdded: _vm.onChooseAdded
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "total-order-wrap" }, [
              _vm.isShowShippingRow
                ? _c(
                    "div",
                    {
                      staticClass: "wrap xl-gutter-8",
                      staticStyle: { "text-align": "right" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("v-ons-checkbox", {
                            attrs: {
                              "input-id": "isCostForShippingIncludedLabel",
                              disabled: !_vm.canEditOrder
                            },
                            model: {
                              value: _vm.isCostForShippingIncluded,
                              callback: function($$v) {
                                _vm.isCostForShippingIncluded = $$v
                              },
                              expression: "isCostForShippingIncluded"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "label-reset",
                              attrs: { for: "isCostForShippingIncludedLabel" }
                            },
                            [_vm._v("Доставка")]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col" }, [
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "label-reset",
                              attrs: { for: "isCostForShippingIncludedLabel" }
                            },
                            [_vm._v(_vm._s(_vm.costForShipping) + " руб.")]
                          )
                        ])
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "wrap total-order" }, [
                _c("div", { staticClass: "col col-label" }, [
                  _c("div", [_vm._v("Сумма")])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col col-total-price" }, [
                  _c("div", { staticClass: "total-price-wrap" }, [
                    _vm._v(_vm._s(_vm.order.totalCost) + " руб.")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.order.hasDiscount,
                      expression: "order.hasDiscount"
                    }
                  ],
                  staticClass: "wrap total-order"
                },
                [
                  _c("div", { staticClass: "col col-label" }, [
                    _c("div", [_vm._v("Сумма со скидкой")])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col col-total-price" }, [
                    _c("div", { staticClass: "total-price-wrap" }, [
                      _vm._v(_vm._s(_vm.order.totalCostWithDiscount) + " руб.")
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Создан: " +
                  _vm._s(_vm._f("moment")(_vm.order.createdAt, "L LT"))
              )
            ]),
            _vm._v(" "),
            _vm.order.sended_at
              ? _c("p", [
                  _vm._v(
                    "Отправлен: " +
                      _vm._s(_vm._f("moment")(_vm.order.sended_at, "L LT"))
                  )
                ])
              : _c("p", [_vm._v("Отправлен: не отправлен")]),
            _vm._v(" "),
            _vm.order.sent_to_onec_at
              ? _c("p", [
                  _vm._v(
                    "Выгружен в 1С: " +
                      _vm._s(
                        _vm._f("moment")(_vm.order.sent_to_onec_at, "L LT")
                      ) +
                      " (номер: " +
                      _vm._s(_vm.order.onec_id) +
                      ")"
                  )
                ])
              : _c("p", [_vm._v("Выгружен в 1С: не выгружен")]),
            _vm._v(" "),
            _vm.order.worker
              ? _c("p", [
                  _vm._v("Ответственный: "),
                  _c(
                    "a",
                    {
                      attrs: { href: "/manager/users/" + _vm.order.worker.id }
                    },
                    [_vm._v(_vm._s(_vm.order.worker.email))]
                  )
                ])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("cancel-order-dialog", { ref: "cancelDialog" }),
      _vm._v(" "),
      _c("confirm-order-dialog", { ref: "confirmDialog" }),
      _vm._v(" "),
      _c("end-edit-dialog", { ref: "endEditDialog" }),
      _vm._v(" "),
      _c("invoice-for-payment-dialog", {
        ref: "invoiceForPaymentDialog",
        attrs: {
          orderId: _vm.order.id,
          defaultEntity: _vm.order.entity
            ? _vm.order.entity.default_provider_entity
            : null,
          entities: _vm.providerEntities
        },
        on: {
          change: function($event) {
            return _vm.$emit("invoiceForPaymentDialogChange", $event)
          },
          sended: function($event) {
            _vm.order.is_invoice_sended = true
          }
        }
      }),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "linkRouteDialog",
        attrs: { items: _vm.routes }
      }),
      _vm._v(" "),
      _c("v-price-types-dialog", {
        ref: "priceTypesDialog",
        attrs: { showNotySendAlert: "true" }
      }),
      _vm._v(" "),
      _c(
        "v-ons-fab",
        {
          style: _vm.order.is_marked_to_send ? "background: #ffbb3d" : "",
          attrs: { position: "bottom right", title: "Отправить заказ в 1С" },
          on: { click: _vm.markToSend }
        },
        [
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.marking,
                  expression: "!marking"
                }
              ]
            },
            [_vm._v("1С")]
          ),
          _vm._v(" "),
          _c("v-ons-icon", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.marking,
                expression: "marking"
              }
            ],
            attrs: { size: "30px", spin: "", icon: "md-spinner" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-81e80ffc", { render: render, staticRenderFns: staticRenderFns })
  }
}