var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-ons-page",
    { staticClass: "manager-order-index" },
    [
      _c("v-ons-toolbar", { attrs: { inline: "true" } }, [
        _c("div", { staticClass: "left" }, [_c("v-ons-back-button")], 1),
        _vm._v(" "),
        _c("div", { staticClass: "center" }, [
          _vm._v("Выберите заказы для объединения")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "background" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "page-loading",
            style: { height: _vm.$window.height - 56 + "px" }
          },
          [_c("v-ons-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.error,
                  expression: "!error"
                }
              ]
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.orders,
                      expression: "orders"
                    }
                  ]
                },
                [
                  _c(
                    "ons-list",
                    [
                      _vm._l(_vm.orders, function(order, key) {
                        return _vm.showOrder(order)
                          ? _c(
                              "ons-list-item",
                              {
                                key: order.id,
                                staticClass: "order-list-item",
                                attrs: { modifier: "longdivider", tappable: "" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "left" },
                                  [
                                    _c("v-ons-checkbox", {
                                      staticStyle: {
                                        "vertical-align": "middle",
                                        margin: "0 12px 0 0"
                                      },
                                      attrs: {
                                        value: order.id,
                                        "input-id": "order-" + key
                                      },
                                      model: {
                                        value: _vm.checked,
                                        callback: function($$v) {
                                          _vm.checked = $$v
                                        },
                                        expression: "checked"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "center" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          "list-item__title",
                                          order.status_id === "sended"
                                            ? "order-new"
                                            : ""
                                        ]
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Заказ №" +
                                            _vm._s(order.id) +
                                            " "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: order.sended_at,
                                                expression: "order.sended_at"
                                              }
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              "от " +
                                                _vm._s(
                                                  _vm._f("moment")(
                                                    order.sended_at,
                                                    "L"
                                                  )
                                                )
                                            )
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                                на сумму\n                                "
                                        ),
                                        order.isNearlyCost
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "5px"
                                                }
                                              },
                                              [_vm._v("~")]
                                            )
                                          : _vm._e(),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              order.hasDiscount
                                                ? order.totalCostWithDiscount
                                                : order.totalCost
                                            ) +
                                            " руб.\n                            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "list-item__subtitle" },
                                      [
                                        _vm._v(
                                          "\n                                Статус: " +
                                            _vm._s(order.status) +
                                            "\n                            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("v-list-item-subtitles", {
                                      attrs: {
                                        item: order,
                                        fields: _vm.$user.getOrderSubtitles(),
                                        selected:
                                          _vm.$user.orderSelectedSubtitles
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showMore,
                              expression: "!showMore"
                            }
                          ],
                          staticStyle: {
                            "text-align": "center",
                            margin: "10px 0"
                          }
                        },
                        [
                          _c(
                            "v-ons-button",
                            {
                              staticStyle: { "min-width": "145px" },
                              on: {
                                click: function($event) {
                                  _vm.showMore = true
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                            Показать ещё\n                        "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.orders.length === 0,
                      expression: "orders.length === 0"
                    }
                  ],
                  staticStyle: {
                    "text-align": "center",
                    "font-size": "15px",
                    "margin-top": "50px"
                  }
                },
                [_vm._v("Ничего не найдено")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.error,
                  expression: "error"
                }
              ],
              staticStyle: {
                "text-align": "center",
                "font-size": "15px",
                "margin-top": "50px"
              }
            },
            [
              _c(
                "div",
                {
                  staticStyle: { "margin-bottom": "15px", "font-size": "20px" }
                },
                [_vm._v("Произошла ошибка.")]
              ),
              _vm._v(" "),
              _c(
                "v-ons-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("orders-update")
                    }
                  }
                },
                [_vm._v("\n                Повторить\n            ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.checked.length > 0
            ? _c(
                "v-ons-fab",
                {
                  attrs: { position: "bottom right" },
                  on: { click: _vm.merge }
                },
                [
                  _c("v-ons-icon", {
                    attrs: { icon: "md-format-valign-center" }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("v-list-dialog", {
        ref: "viewParamsModal",
        attrs: { multiple: "true", title: "Опции просмотра" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f5688a7", { render: render, staticRenderFns: staticRenderFns })
  }
}